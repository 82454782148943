<template>
    <span></span>
</template>
<script>
/* eslint-disable */
import Cookies from 'js-cookie'
import uploadFormMixin from '@apps/media/components/MediaUploadForm/mixins/uploadFormMixin'
import moment from 'moment/moment'

export default {
    name: 'UploadGoogleDrivePicker',
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    mixins: [ uploadFormMixin ],
    watch: {
        value(flag) {
            this.isPicker = flag
        },
        isPicker(flag) {
            this.$emit('input', flag)
        }
    },
    data() {
        return {
            isPicker: false,
            pickerApiLoaded: false,
            developerKey: process.env.VUE_APP_GOOGLE_DRIVE_KEY,
            clientId: process.env.VUE_APP_GOOGLE_DRIVE_CLIENT_ID,
            scope: 'https://www.googleapis.com/auth/drive.file',
            oauthToken: null
        }
    },
    mounted() {
        this.oauthToken = Cookies.get('gapi_sid')
        // console.log('mounted', Cookies.get('gapi_sid'))
        this.driveIconClicked()
    },
    methods: {
        async driveIconClicked() {
            // console.log('Clicked')
            if(!this.oauthToken) {
                await gapi.load('auth2', () => {
                    gapi.auth2.authorize(
                        {
                            client_id: this.clientId,
                            scope: this.scope,
                            immediate: false
                        },
                        this.handleAuthResult
                    )
                })
            }

            gapi.load('picker', () => {
                // console.log('Picker Loaded')
                this.pickerApiLoaded = true
                this.createPicker()
            })
        },
        handleAuthResult(authResult) {
            // console.log('Handle Auth result', authResult)

            if(authResult && !authResult.error) {
                this.oauthToken = authResult.access_token

                const expires = moment.utc().diff(authResult.expires_at, 'days')

                if(expires > 0) {
                    Cookies.set('gapi_sid', this.oauthToken)
                }

                this.createPicker()
            }
        },
        createPicker() {
            // console.log("Create Picker", google.picker)

            if(this.pickerApiLoaded && this.oauthToken) {
                const view = new google.picker.DocsView()
                view.setIncludeFolders(true)
                view.setParent('root')
                view.setMode(google.picker.DocsViewMode.LIST)

                const picker = new google.picker.PickerBuilder()
                    .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                    .addView(view)
                    .setLocale(this.$store.getters.locale)
                    .setOAuthToken(this.oauthToken)
                    .setDeveloperKey(this.developerKey)
                    .setCallback(this.pickerCallback)
                    .build()

                picker.setVisible(true)
            }
        },
        async pickerCallback(data) {
            // console.log("PickerCallback", data)

            if(data[google.picker.Response.ACTION] !== google.picker.Action.PICKED) {
                if(data[google.picker.Response.ACTION] === 'cancel') {
                    this.$emit(data[google.picker.Response.ACTION])
                }

                return
            }

            this.$emit('input', data.docs)
        }
    }
}
</script>

<style lang=scss>

</style>
