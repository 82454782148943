<template>
    <div class="upload-file-form">
        <upload-google-drive-picker
            v-if="showPicker"
            @input="uploadFilesHandler"
            @cancel="onCancel"
        />
        <upload-google-drive-form-progress
            v-else
            :items="selectedFiles"
        />
    </div>
</template>

<script>
import { uploadGoogleDrive } from '@apps/media/api'
import UploadGoogleDrivePicker
from '@apps/media/components/MediaUploadForm/UploadGoogleDriveForm/UploadGoogleDrivePicker'
import UploadGoogleDriveFormProgress from '@apps/media/components/MediaUploadForm/UploadGoogleDriveForm/UploadGoogleDriveFormProgress'
import { uploadStatus } from '@apps/media/tools/config'
import uploadFormMixin from '@apps/media/components/MediaUploadForm/mixins/uploadFormMixin'

export default {
    name: 'UploadGoogleDriveForm',
    mixins: [uploadFormMixin],
    components: {
        UploadGoogleDriveFormProgress,
        UploadGoogleDrivePicker
    },
    computed: {
        showPicker() {
            return this.isPicker &&
                this.selectedFiles.length === 0
        }
    },
    data() {
        return {
            isPicker: true,
            selectedFiles: []
        }
    },
    methods: {
        async uploadFilesHandler(files) {
            this.$emit('loading', files.length)

            files.forEach((item, index) => {
                this.selectedFiles.push({
                    file: item,
                    status: uploadStatus.LOADING
                })
            })

            for (let i = 0; i < files.length; i++) {
                const item = files[i]
                const data = {
                    url: item.url,
                    title: item.name,
                    options: {
                        meta: item
                    }
                }

                if(this.parent) {
                    data.parent = this.parent
                }

                try {
                    const response = await uploadGoogleDrive(data)

                    this.selectedFiles[i].status = uploadStatus.SUCCESS

                    this.$emit('sequence', response.data.data)
                } catch (error) {
                    this.selectedFiles[i].status = uploadStatus.ERROR
                }
            }

            this.$emit('success', { message: this.$trans('Uploaded successful') })
        },
        onCancel() {
            this.isPicker = false
            this.$emit('cancel')
        }
    }
}
</script>

<style lang=scss>
</style>
