<template>
    <v-sheet
        min-height="216px"
        color="transparent"
        class="upload-file-form-progress"
    >
        <template
            v-for="(item, index) in items"
        >
            <v-divider
                v-if="index > 0"
                :key="`v-divider-${index}`"
                class="my-2"
            />
            <div
                :key="`file-item-${index}`"
                class="d-flex align-center"
            >
                <div class="d-flex align-center pr-3">
                    <v-sheet
                        height="40"
                        width="64"
                        class="d-flex align-center justify-center"
                    >
                        <media-icon
                            :item="{src: item.file.url}"
                            size="32"
                        />
                    </v-sheet>
                </div>
                <div class="text-truncate text-sm flex-fill">
                    {{ item.file.name }}
                </div>
                <div class="pl-4">
                    <v-progress-circular
                        v-if="item.status === uploadStatus.LOADING"
                        indeterminate
                        size="16"
                        width="2"
                        color="primary"
                    ></v-progress-circular>
                    <w-icon
                        v-else-if="item.status === uploadStatus.SUCCESS"
                        color="success"
                        value="CHECK"
                    />
                    <w-icon
                        v-else-if="item.status === uploadStatus.ERROR"
                        color="error"
                        value="ERROR"
                    />
                </div>
            </div>
        </template>
    </v-sheet>
</template>

<script>
import MediaIcon from '@apps/media/components/MediaIcon'
import { isImage } from '@apps/media/tools'
import { uploadStatus } from '@apps/media/tools/config'

export default {
    name: 'UploadFileFormProgress',
    components: { MediaIcon },
    props: {
        items: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    computed: {
        uploadStatus() {
            return uploadStatus
        }
    },
    methods: {
        isImage(src) {
            return isImage(src)
        },
        imagePreview(file) {
            return window.URL.createObjectURL(file)
        }
    }
}
</script>

<style lang=scss>

</style>
